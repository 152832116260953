<template>
  <div class="loginPage_logo logo"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.logo {
    background: url(./resources/logo_pochta.svg) no-repeat;
    width: 100%;
}
</style>
